<template>
  <div class="qrcode" id="qrcode" ref="qrCodeUrl">
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  props: {
    texter: {
      // type: String,
      default: "暂无",
    },
  },
  // 组件状态值
  data () {
    return {
    }
  },
  methods: {
    creatQrCode () {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: JSON.stringify(this.texter), // 需要转换为二维码的内容
        width: 80,
        height: 80,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      console.log(qrcode);
    }
  },
  created () {
  },
  mounted () {
    this.creatQrCode()
  }
}
</script>
<style scoped >
.qrcode {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
