<template>
  <div class="about">
    <v-header @getLastRouter="getLastRouter" />
    <v-sidebar :routeName="routeName" />
    <div
      class="content-box"
      :class="{ 'content-collapse': collapse,'boxscrollshow': showscroll,'boxscrollhidden': hiddenscroll,'hiddenyscroll':hiddenyscroll }"
    >
      <v-tags
        style="position: fixed; z-index: 2;border-left: 1px solid #ddd; "
        :style="{ width: width }"
        @getLastRouter="getLastRouter"
      ></v-tags>
      <div
        class="content"
        :style="{ minHeight: loadingheight }"
        style="
       min-width: 580px;
          background: #fff;
          position: relative;
          top:35px;
          padding: 10px 10px 10px 10px;
          box-sizing:border-box;
          
        "
      >
        <router-view v-slot="{ Component }">
          <transition
            name="move"
            mode="out-in"
          >
            <keep-alive :include="tagsList">
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>
<script>
import vHeader from "../components/Header";
import vSidebar from "../components/Sidebar";
import vTags from "../components/Tags.vue";
export default {
  data () {
    return {
      routeName: null,
      width: "",
      loadingheight: "",
      hiddenscroll: false,
      showscroll: false,
      hiddenyscroll: false,
    };
  },
  components: {
    vHeader,
    vSidebar,
    vTags,
  },
  computed: {
    tagsList () {
      return this.$store.state.tagsList.map((item) => item.name);
    },
    collapse () {
      return this.$store.state.collapse;
    },
  },
  methods: {
    getLastRouter (routerName) {
      this.routeName = routerName;
    },
    changetage () {
      // tag 的宽度
      if (this.collapse) {
        // 关闭
        this.width = (document.body.clientWidth - 65) + "px";
      } else {
        // 打开
        this.width = (document.body.clientWidth - 251) + "px";

      }

    },
  },
  mounted () {
    // tag
    this.changetage()
    const divWidth = document.getElementsByClassName("content");
    if (divWidth[0].clientWidth > 1320) {
      // 宽度大于1320 隐藏滚动条
      this.hiddenscroll = true
      this.showscroll = false;
    } else {
      // 宽度小于1320 显示滚动条
      this.hiddenscroll = false
      this.showscroll = true;
    }
    const loadingheight = document.body.clientHeight;
    this.loadingheight = loadingheight - 91 + "px";
    if (loadingheight < 416) {
      this.hiddenyscroll = true
    } else {
      this.hiddenyscroll = false
    }
    window.addEventListener(
      "resize",
      () => {
        const divWidth = document.getElementsByClassName("content");
        this.changetage()
        const loadingheight = document.body.clientHeight;
        this.loadingheight = loadingheight - 91 + "px";
        if (divWidth[0].clientWidth > 1280) {
          // 宽度大于1320 隐藏滚动条
          this.hiddenscroll = true
          this.showscroll = false;
        } else {
          // 宽度小于1320 显示滚动条
          // this.hiddenscroll = false
          // this.showscroll = true;
          // 版本2隐藏掉最下面的
          this.hiddenscroll = true
          this.showscroll = false;
        }
        if (loadingheight < 416) {
          this.hiddenyscroll = true
        } else {
          this.hiddenyscroll = false
        }
      },
      false
    );
  },
  watch: {
    collapse () {
      if (!this.timerflag) {
        this.timerflag = true;
        let that = this;
        setTimeout(function () {
          that.timerflag = false;
          that.changetage()
        }, 330);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-select-dropdown__item {
  background: #fff;
}
.content-box {
  height: calc(100% - 35px);
  padding-bottom: 0;
  background: #fff;
  // overflow: scroll;
}
/deep/.boxscrollshow {
  overflow-x: scroll !important;
  // overflow-y: hidden !important;
}
/deep/.boxscrollhidden {
  overflow-x: hidden !important;
  // overflow-y: scroll !important;
}
/deep/.hiddenyscroll {
  overflow-y: hidden !important;
}
</style>
