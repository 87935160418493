<template>
	<div class="searchArea">
		<el-card :body-style="{ padding: '0px' }">
			<el-form :model="searchMode" height="160px" label-width="100px" label-position="right">
				<el-row :gutter="20" style="margin-right: 0px">
					<el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
						<div class="searchTitle">
							{{ isReport ? '导出报表条件' : '查询条件' }}
						</div>
					</el-col>
					<el-col
						:xs="12"
						:sm="12"
						:md="12"
						:lg="12"
						:xl="12"
						:style="{
							textAlign: 'right',
							paddingRight: '5px',
							paddingTop: '10px',
						}"
					>
						<el-button
							type="primary"
							size="small"
							@click="exportData"
							v-if="isReport && operateInfo && operateInfo.menuName === '入库明细报告'"
							:disabled="
								operateInfo &&
									operateInfo.operateInfo.find(
										(item) => item.operateId === 'enterStoreDetailReport'
									).isDisable
							"
							>{{
								operateInfo &&
									operateInfo.operateInfo.find(
										(item) => item.operateId === 'enterStoreDetailReport'
									).operateName
							}}</el-button
						>
						<el-button
							type="primary"
							size="small"
							@click="exportData"
							v-if="isReport && operateInfo && operateInfo.menuName === '出库明细报告'"
							:disabled="
								operateInfo &&
									operateInfo.operateInfo.find((item) => item.operateId === 'outStoreDetailReport')
										.isDisable
							"
							>{{
								operateInfo &&
									operateInfo.operateInfo.find((item) => item.operateId === 'outStoreDetailReport')
										.operateName
							}}</el-button
						>
						<el-button
							type="primary"
							size="small"
							@click="exportData"
							v-if="summaryExport && operateInfo && operateInfo.menuName !== '收货单列表'"
							:disabled="
								operateInfo &&
									operateInfo.operateInfo.find((item) => item.operateId === 'export').isDisable
							"
							>{{
								operateInfo &&
									operateInfo.operateInfo.find((item) => item.operateId === 'export').operateName
							}}</el-button
						>
						<el-button
							type="danger"
							size="small"
							@click="deleteAll"
							v-if="batchDelete"
							:disabled="
								operateInfo &&
									operateInfo.operateInfo.find((item) => item.operateId === 'del').isDisable
							"
							>{{
								operateInfo &&
									operateInfo.operateInfo.find((item) => item.operateId === 'del').operateName
							}}</el-button
						>
						<el-button
							v-if="!isReport"
							type="primary"
							size="small"
							@click="search"
							:disabled="
								operateInfo &&
									operateInfo.operateInfo.find((item) => item.operateId === 'search').isDisable
							"
							>{{
								operateInfo &&
									operateInfo.operateInfo.find((item) => item.operateId === 'search').operateName
							}}</el-button
						>
					</el-col>
				</el-row>
				<div style="border-top: 1px solid #eee">
					<el-row :gutter="20" style="padding-left: 15px">
						<div v-for="(item, index) in searchLabel" :key="index" class="conditionArea">
							<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="index <= 3">
								<el-form-item :label="item.label" size="small">
									<el-input
										v-if="item.type === 'input'"
										:placeholder="item.placeholder"
										v-model="item.value"
										size="small"
									></el-input>
									<el-date-picker
										v-if="item.type === 'date'"
										:placeholder="item.placeholder"
										v-model="item.value"
										align="right"
										type="date"
										size="small"
										style="width: 200px"
									></el-date-picker>
									<div v-if="item.type === 'dateSlot'" style="width: 390px">
										<el-date-picker
											:placeholder="item.placeholder[0]"
											v-model="item.value.startDate"
											align="right"
											type="date"
											size="small"
											style="width: 180px"
										/>&nbsp;&nbsp;~&nbsp;
										<el-date-picker
											:placeholder="item.placeholder[1]"
											v-model="item.value.endDate"
											align="right"
											type="date"
											size="small"
											style="width: 180px"
										/>
									</div>
									<div v-if="item.type === 'dateSingle'">
										<el-date-picker
											:placeholder="item.placeholder"
											v-model="item.value"
											align="right"
											type="date"
											size="small"
											style="width: 180px"
										/>
									</div>
									<div v-if="item.type === 'dateMonth'" style="width: 390px">
										<el-date-picker
											:placeholder="item.placeholder[0]"
											v-model="item.value.startDate"
											align="right"
											type="month"
											size="small"
											style="width: 180px"
										/>
										&nbsp;&nbsp;~&nbsp;
										<el-date-picker
											:placeholder="item.placeholder[1]"
											v-model="item.value.endDate"
											align="right"
											type="month"
											size="small"
											style="width: 180px"
										/>
									</div>
									<el-select
										v-if="item.type == 'select'"
										:placeholder="item.placeholder"
										v-model="item.value"
										clearable
										size="small"
										@change="onChange(searchLabel[index])"
										:no-data-text="'&nbsp'"
									>
										<el-option
											v-for="item in searchValue[index].options"
											:key="item.value"
											:value="item.value"
											:label="item.label"
										>
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</div>
						<el-col
							:xs="4"
							:sm="4"
							:md="4"
							:lg="4"
							:xl="4"
							v-if="searchLabel.length > 4"
							style="height: 42px; line-height: 43px"
						>
							<span
								style="
                  color: #409eff;
                  cursor: pointer;
                  font-size: 12px;
                  font-weight: 700;
                "
								@click="dispaly()"
							>
								+更多条件</span
							>
						</el-col>
					</el-row>
					<div v-show="isDisplay">
						<el-row
							v-show="searchInput === true"
							:gutter="15"
							style="padding-left: 15px;margin-left: 0px"
						>
							<div v-for="(item, index) in searchLabel" :key="index" class="conditionArea">
								<el-form-item v-if="item.type === 'search'" :label="item.label" size="small">
									<el-input
										:placeholder="item.placeholder"
										v-model="item.value"
										size="small"
										style="width: 133px"
										disabled
									></el-input>
									<el-button
										class="clearbutton"
										@click="searchsth(item.label)"
										icon="el-icon-search"
										style="color: #070607;padding: 5px 10px;"
									></el-button>
									<el-button
										class="clearbutton"
										@click="clearchsth(item.label)"
										icon="el-icon-close"
										style="color: #070607;margin-left: 0px;padding: 5px 10px;"
									></el-button>
								</el-form-item>
							</div>
						</el-row>
						<el-row :gutter="20" style="padding-left: 15px;">
							<div v-for="(item, index) in searchLabel" :key="index" class="conditionArea">
								<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="index > 3">
									<el-form-item v-if="item.type !== 'search'" :label="item.label" size="small">
										<el-input
											v-if="item.type === 'input'"
											:placeholder="item.placeholder"
											v-model="item.value"
											size="small"
										></el-input>
										<el-date-picker
											v-if="item.type === 'date'"
											:placeholder="item.placeholder"
											v-model="item.value"
											align="right"
											type="date"
											size="small"
											style="width: 180px"
										></el-date-picker>
										<div v-if="item.type === 'dateSlot'" style="width: 390px">
											<el-date-picker
												:placeholder="item.placeholder[0]"
												v-model="item.value.startDate"
												align="right"
												type="date"
												size="small"
												style="width: 180px"
											/>&nbsp;&nbsp;~&nbsp;
											<el-date-picker
												:placeholder="item.placeholder[1]"
												v-model="item.value.endDate"
												align="right"
												type="date"
												size="small"
												style="width: 180px"
											/>
										</div>
										<div v-if="item.type === 'dateSingle'">
											<el-date-picker
												:placeholder="item.placeholder"
												v-model="item.value"
												align="right"
												type="date"
												size="small"
												style="width: 180px"
											/>
										</div>
										<el-select
											v-if="item.type == 'select'"
											:placeholder="item.placeholder"
											v-model="item.value"
											clearable
											size="small"
											@change="onChange(searchLabel[index])"
											:no-data-text="'&nbsp'"
										>
											<el-option
												v-for="item in searchValue[index].options"
												:key="item.value"
												:value="item.value"
												:label="item.label"
											>
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</div>
						</el-row>
					</div>
				</div>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	export default {
		props: {
			searchLabel: {
				type: Object,
				request: true,
			},
			searchValue: {
				type: Object,
				request: true,
			},
			operateInfo: {
				type: Object,
				request: true,
			},
			summaryExport: Boolean,
			searchInput: {
				type: Boolean,
				default: false,
			},
			batchDelete: Boolean,
			isDisplayThree: Boolean,
			isReport: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				searchConditions: [],
				exportDataConditions: [],
				isDisplay: false,
			};
		},
		mounted() {},
		methods: {
			searchsth(data){
				this.$emit('searchsth',data);
			},
			clearchsth(data){
				this.$emit('clearchsth',data);
			},
			search() {
				this.searchConditions = this.searchLabel;
				this.$emit('chooseSearchCondition', this.searchConditions);
			},
			exportData() {
				this.exportDataConditions = this.searchLabel;
				this.$emit('exportDataCondition', this.exportDataConditions);
			},
			deleteAll() {
				this.$emit('batchDeleteData');
			},
			dispaly() {
				if (this.isDisplay) {
					this.isDisplay = false;
				} else {
					this.isDisplay = true;
				}
			},
			onChange(key) {
				this.$emit('onChange', key);
			},
		},
	};
</script>
<style lang="less" scoped>
	/deep/.el-row {
		margin-right: 0px;
	}
	.searchArea {
		width: auto;
		height: auto;
		margin-bottom: 10px;
	}
	.searchTitle {
		font-size: 18px;
		font-weight: bold;
		line-height: 50px;
		margin-left: 15px;
	}
	.el-input,
	.el-select {
		width: 180px;
	}
	.el-form-item {
		margin-bottom: 0px !important;
	}
	.el-form-item__label {
		line-height: 28px;
	}
	.moreCondition {
		font-size: 12px;
		font-weight: bold;
		color: #409eff;
		cursor: pointer;
		text-align: left;
		padding-right: 20px;
		white-space: nowrap;
	}
	/deep/.moreConditionForContractlist {
		font-size: 12px;
		font-weight: bold;
		color: #409eff !important;
		cursor: pointer;
		text-align: left;
		padding-right: 20px;
		white-space: nowrap;
	}
	.conditionArea {
		margin: 5px 0;
	}
	/*按钮灰化样式*/
	.el-button--danger.is-disabled,
	.el-button--danger.is-disabled:active,
	.el-button--danger.is-disabled:focus,
	.el-button--danger.is-disabled:hover {
		color: #23282c !important;
		background-color: #f2f2f2 !important;
		border-color: #ddd !important;
	}
	.el-button--primary.is-disabled,
	.el-button--primary.is-disabled:active,
	.el-button--primary.is-disabled:focus,
	.el-button--primary.is-disabled:hover {
		color: #23282c !important;
		background-color: #f2f2f2 !important;
		border-color: #ddd !important;
	}
	/deep/.el-input--small .el-input__inner {
		font-size: 14px;
	}
</style>
