<template>
  <div class="sidebar" id="Sidebar">
    <el-menu class="sidebar-el-menu" active-background-color="#000"
      :collapse="collapse" text-color="#fff" active-text-color="#fff"
      :unique-opened="false" :default-active="[routeDefault]" router
      :collapse-transition="false">
      <template v-for="item in items">
        <template v-if="item && item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i :class="'iconfont ' + item.icon"
                style="font-size: 12px; margin-right: 8px"></i>
              <span style="font-size: 12px">{{ item.title }}</span>
            </template>
            <template v-for="(subItem) in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index"
                :key="subItem.index">
                <template #title>{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i"
                  :index="threeItem.index">
                  {{ threeItem.title }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.index">
                <div style="margin-left: -15px">
                  <div class="left-dotted-border">
                    <div class="bottom-dotted-border"></div>
                  </div>
                  <span :class="{fontcolor:fontcolor== subItem.title}"
                    style="font-size: 12px; margin-left: 4px">{{
                    subItem.title
                  }}</span>
                </div>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else-if="item">
          <el-menu-item :index="item.index" :key="item.index">
            <i style="font-size: 12px; color: #fff; margin-right: 8px"
              :class="'iconfont ' + item.icon"></i>
            <span style="font-size: 12px" v-if="!collapse">{{
              item.title
            }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: ["routeName"],
  data () {
    return {
      items: [],
      fontcolor: ''

    };
  },
  computed: {
    routeDefault () {
      return this.routeName;
    },
    collapse () {
      return this.$store.state.collapse;
    },
    isDisabled () {
      return this.$route.meta.title;
    },
  },
  mounted () {
    this.items = JSON.parse(sessionStorage.getItem("menuInfo"));
  },
  methods: {

  },
};
</script>

<style lang='less' scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 55px;
  bottom: 0;
  overflow-y: scroll;
  /* background: #0069b5;
  height: 100%; */
}

.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  background: #0069b5;
  width: 250px;
}
.sidebar ul {
  height: 100%;
  // min-height: 884px;
}
// 菜单背景色
/deep/ .el-menu {
  background-color: #0069b5 !important;
  border-right: 1px solid #0069b5;
}
// 无子级菜单
.el-menu-item,
.el-submenu__title {
  background-color: #0069b5;
  height: 36px;
  line-height: 36px;
}
// 一级菜单样式
/deep/.el-submenu__title {
  line-height: 34px !important;
  height: 34px !important;
  background: #0069b5 !important;
  i {
    color: #fff !important;
  }
}
/deep/.el-submenu__title:hover {
  outline: 0;
  background-color: #fff !important;
  color: #000 !important;
  i {
    color: #000 !important;
  }
}
// 二级菜单样式
/deep/.el-submenu .el-menu-item {
  background-color: #479fde !important;
  // height: 100% !important;
  line-height: 34px !important;
  height: 34px !important;
}
/deep/.el-menu-item:hover {
  outline: 0;
  background-color: #fff !important;
  color: #000 !important;
  i {
    color: #000 !important;
  }
}
// 二级菜单左边虚线样式
.left-dotted-border {
  line-height: 34px;
  width: 10px;
  height: 34px;
  border-left: 1px dotted #fff;
  display: inline-block;
}
//  二级菜单左边虚线底部样式
.bottom-dotted-border {
  width: 10px;
  height: 17px;
  line-height: 17px;
  border-bottom: 1px dotted #fff;
}
.el-popper__arrow::before {
  content: none !important;
}
.el-popper {
  border-radius: 0 !important;
}
.top-dotted-border {
  margin-top: 1px;
  line-height: 34px;
  width: 10px;
  height: 34px;
  border-left: 1px dotted #fff;
  display: inline-block;
}
.el-button--medium.is-circle,
.el-popper {
  padding: 0 !important;
}
.fontcolor {
  color: #000 !important;
}
/deep/.el-menu--popup {
  padding: 0 !important;
}
/deep/.el-menu--popup:hover {
  padding: 0 !important;
  background-color: #fff;
  color: #000 !important;
}
// 菜单关闭 鼠标悬停
.el-menu-item:hover,
.el-submenu__title:hover {
  background: #fff !important;
  color: #000 !important;
}
//首页 代办事项 选中颜色
/deep/.el-menu-item.is-active {
  background-color: #0069b5;
}
</style>
