<template>
  <div class="commonButton-body-button">
    <el-button v-if="Yes" type="primary" size="mini " :disabled="disableType"
      class="commonButton-body-button-button" @click.stop="toSubmit">
      {{ Yes.label }}
    </el-button>
    <el-button v-if="No" type="danger" size="mini "
      class="commonButton-body-button-button" @click.stop="cancel">
      {{ No.label }}
    </el-button>
    <el-button v-if="Back" type="primary" size="mini "
      class="commonButton-body-button-button" @click.stop="toBack">
      {{ Back.label }}
    </el-button>
     <el-button v-if="Down" type="success" size="mini " :disabled="disableType"
      class="commonButton-body-button-button" @click.stop="toSubmit">
      {{ Down.label }}
    </el-button>
  </div>
</template>
<script>
export default {
  props: ["Yes", "No", "Back", "disableType","Down"],
  methods: {
    toSubmit () {
      this.$emit("toSubmit", true);
    },
    cancel () {
      this.$emit("cancel", false);
    },
    toBack () {
      this.$router.push({
        name: this.$router.currentRoute.value.meta.parentName,
        params: {
          isBack: true,
        },
      });
    },
  },
};
</script>

<style>
.commonButton-body-button {
  float: right;
}
/* .commonButton-body-button-button {
  width: 80px;
} */
</style>
