<template>
  <div id="CommonDialog">
    <div
      v-if="isShowDialog"
      :style="{
        position: 'fixed',
        zIndex: 10,
        background: 'rgba(209, 209, 209, 0.5)',
        left: left,
        top: top,
        height: height,
        width: width,
      }"
    >
      <div
        class="prompt-content"
        :style="{
          height:
            Outsidecontainerheight ||
            Outsidecontainerheightfour ||
            Outsidecontainerheightpaging,
        }"
        :class="{
          'prompt-content-change': isShowChooseMaterial,
          'prompt-content-table': isTableDialog,
          'prompt-condition-change': overTwoCondition,
          'promptpaging-content-change': isTableDialogpaging,
        }"
      >
        <div
          class="prompt-title"
          :class="{ 'prompt-stresstitle': stresstitle }"
        >
          <span type="text" class="dialog-back" @click="_back()" v-if="isBack"
            >返回</span
          >
          {{ title }}
        </div>
        <button
          type="button"
          aria-label="Close"
          class="el-message-box__headerbtn"
          @click="_cancel()"
        >
          <i class="el-message-box__close el-icon-close"></i>
        </button>
        <div
          v-if="isTableDialog"
          class="cu-form-group btn-item"
          :style="{
            height:
              btnbottomheight || btnbottomheightfour || btnbottomheightpaging,
          }"
          :class="{
            'btn-item-MaterialDialog': isShowChooseMaterial,
            'btn-item-not-Messagedialog': !isShowChooseMaterial,
            'btn-item-MaterialDialogpaging': isTableDialogpaging,
          }"
        >
          <div
            v-if="
              isShowChooseMaterial && type == 'tableDialog' && checkBox != '1'
            "
          >
            <div class="searchMaterial" id="searchMaterial">
              <div
                style="
                  border-bottom: 1px solid #ddd;
                  padding: 0 10px;
                  font-size: 14px;
                "
              >
                <el-form :model="searchConditionInfo">
                  <el-row :gutter="20">
                    <el-col
                      :span="5"
                      v-for="(item, index) in searchConditionInfo"
                      :key="index"
                      :style="{
                        padding: '0px',
                        marginLeft: '20px',
                        minWidth: '225px',
                      }"
                    >
                      <el-form-item :label="item.label + ':'">
                        <el-input
                          v-show="item.type === 'input' || !item.type"
                          v-model="item.value"
                          :placeholder="item.placeholder"
                          size="mini"
                          style="font-size: 14px"
                        >
                        </el-input>
                        <!-- 年月时间选择器付款弹框检索条件 -->
                        <el-date-picker
                          :placeholder="item.placeholder"
                          v-model="item.value"
                          align="right"
                          type="month"
                          size="mini"
                          style="font-size: 14px; width: 100%"
                          v-if="item.type === 'dateMonth'"
                        />
                        <div v-show="item.type === 'select'">
                          <el-autocomplete
                            ref="autocomplete"
                            size="mini"
                            @change="pickSelect"
                            v-model="item.value"
                            @iconclick="sh"
                            :onFocus="onFocus"
                            :onBlur="onBlur"
                            v-bind:disabled="disabled"
                            :fetch-suggestions="querySearch"
                            value-key="label"
                            :placeholder="item.placeholder"
                            @select="pickSelect(item)"
                            @mouseleave="onmouseleave"
                            @mouseover="onmouseover(item.value)"
                            :clearable="!showershow"
                            style="font-size: 14px"
                          >
                            <template #suffix>
                              <i
                                :class="{
                                  'el-icon-arrow-up': showershow,
                                  'el-icon-arrow-down': showershow,
                                }"
                                @click="handleIconClick(item.value)"
                              ></i>
                            </template>
                          </el-autocomplete>
                        </div>
                      </el-form-item>
                    </el-col>
                    <div style="float: left">
                      <el-button
                        v-if="crossFactorySearch === '01'"
                        style="width: 80px; height: 28px; margin: 16px 0 0 10px"
                        type="primary"
                        size="mini"
                        @click="searchInfo"
                        >检索
                      </el-button>
                      <el-button
                        v-if="
                          crossFactorySearch !== '01' &&
                          crossFactorySearch !== '02'
                        "
                        style="
                          width: 80px;
                          height: 28px;
                          margin: 16px 10px 0 10px;
                        "
                        type="primary"
                        size="mini"
                        @click="searchInfo"
                        >检索
                      </el-button>
                      <el-button
                        v-if="crossFactorySearch === '02'"
                        style="
                          width: 80px;
                          height: 28px;
                          margin: 16px 10px 0 10px;
                        "
                        type="primary"
                        size="mini"
                        @click="crossFactorySearchInfo"
                      >
                        <span style="margin-left: -5px">跨工厂检索</span>
                      </el-button>
                      <el-button
                        v-if="
                          crossFactorySearch !== '01' &&
                          crossFactorySearch !== '02' &&
                          crossFactorySearch
                        "
                        style="
                          width: 80px;
                          height: 28px;
                          margin: 16px 10px 0 10px;
                        "
                        type="primary"
                        size="mini"
                        @click="crossFactorySearchInfo"
                      >
                        <span style="margin-left: -5px">跨工厂检索</span>
                      </el-button>
                    </div>
                  </el-row>
                </el-form>
              </div>
              <div
                class="table_dialog"
                style="padding: 0 10px; overflow-x: scroll"
                v-show="PopDisplay"
              >
                <el-table
                  ref="multipleTable"
                  :data="materialListData"
                  :height="isFixedChildHeight ? tableChildHeight : null"
                  border
                  style="width: 100%; margin: 10px 0"
                  :empty-text="emptyMessage"
                  @selection-change="handleSelectionChange"
                  @sort-change="sortChange"
                  stripe
                  @row-click="handleRowClick"
                  :header-cell-style="{
                    background: '#fff',
                    color: '#23282c',
                  }"
                  :row-class-name="tabRowClassName"
                  :row-key="getRowKeys"
                  :highlight-current-row="isHighlightCurrentRow"
                >
                  <el-table-column
                    width="55"
                    type="selection"
                    align="center"
                    :reserve-selection="true"
                    :resizable="false"
                  ></el-table-column>
                  <el-table-column
                    :prop="item.porp"
                    :label="item.label"
                    :min-width="item.percent ? item.percent : '15%'"
                    v-for="item in materialListHead"
                    :key="item.key"
                    align="center"
                    :sortable="true"
                    :show-overflow-tooltip="true"
                    :resizable="false"
                  ></el-table-column>
                </el-table>
              </div>
              <div class="footer" v-if="PagingValue" style="margin-top: -15px">
                <span class="totalPagesTip" style="margin-top: 15px">
                  共<span class="totalPages">{{ totalnumbertitle }}</span
                  >条数据
                </span>
                <el-pagination
                  class="pagination"
                  background
                  layout="prev, pager, next"
                  :page-size="isCreate ? '50' : '100'"
                  :total="totalnumbertitle"
                  hide-on-single-page
                  @current-change="continueToQuery"
                  :current-page="offset"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div v-else-if="!isShowChooseMaterial && type == 'tableDialog'">
            <div
              style="
                border-bottom: 1px solid #ddd;
                padding: 0 10px;
                font-size: 14px;
              "
            >
              <el-form :model="searchConditionInfo">
                <el-row :gutter="20">
                  <el-col
                    :span="5"
                    v-for="(item, index) in searchConditionInfo"
                    :key="index"
                    :style="{
                      padding: '0px',
                      marginLeft: '20px',
                      minWidth: '225px',
                    }"
                  >
                    <el-form-item :label="item.label + ':'">
                      <el-input
                        v-model="item.value"
                        :placeholder="item.placeholder"
                        size="mini"
                        style="font-size: 14px"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <div style="float: left">
                    <el-button
                      style="
                        width: 80px;
                        height: 28px;
                        margin: 16px 10px 0 10px;
                      "
                      type="primary"
                      size="mini"
                      @click="searchInfo"
                      >检索
                    </el-button>
                  </div>
                </el-row>
              </el-form>
            </div>
            <div
              class="table_dialog"
              style="padding: 0 10px"
              v-show="PopDisplay"
            >
              <div v-if="!createNumber">
                <el-table
                  ref="multipleTable"
                  :data="receivingRecordData"
                  :height="isFixedChildHeight ? tableChildHeight : null"
                  border
                  @selection-change="handleSelectionChange"
                  @sort-change="sortChange"
                  :empty-text="emptyMessage"
                  style="margin-top: 10px"
                  @row-click="handleRowClick"
                  :header-cell-style="{ background: '#fff', color: '#23282c' }"
                  :row-class-name="tabRowClassName"
                  :highlight-current-row="isHighlightCurrentRow"
                >
                  <el-table-column
                    width="55"
                    type="selection"
                    align="center"
                    :resizable="false"
                  >
                  </el-table-column>
                  <el-table-column
                    :prop="item.porp"
                    :label="item.label"
                    :min-width="item.percent || '15%'"
                    v-for="item in receivingRecordHead"
                    :key="item.key"
                    align="center"
                    :sortable="true"
                    :show-overflow-tooltip="true"
                    :resizable="false"
                  ></el-table-column>
                </el-table>
              </div>
              <div class="footer" v-if="PagingValue" style="margin-top: -15px">
                <span class="totalPagesTip" style="margin-top: 35px">
                  共<span class="totalPages">{{ totalnumbertitle }}</span
                  >条数据
                </span>
                <el-pagination
                  style="margin-top: 30px"
                  class="pagination"
                  background
                  layout="prev, pager, next"
                  page-size="100"
                  :total="totalnumbertitle"
                  hide-on-single-page
                  @current-change="continueToQuery"
                  :current-page="offset"
                >
                </el-pagination>
              </div>
              <div
                v-else
                style="padding: 10px 0"
                v-show="PopDisplay && createShutDown"
              >
                <el-table
                  :data="materialListData"
                  style="width: 100%"
                  border
                  :header-cell-style="{ background: '#fff', color: '#23282c' }"
                  :row-class-name="tabRowClassName"
                  :highlight-current-row="isHighlightCurrentRow"
                >
                  <el-table-column type="expand">
                    <template #default>
                      <div id="dialog-table-in-table">
                        <el-table
                          :data="materialListInfoData"
                          style="width: 95%; margin-left: 47px"
                          border
                          :header-cell-style="{
                            background: '#fff',
                            color: '#23282c',
                          }"
                          :row-class-name="tabRowClassName"
                          :highlight-current-row="isHighlightCurrentRow"
                        >
                          <el-table-column
                            :prop="item.prop"
                            :label="item.label"
                            v-for="item in materialListInfoHead"
                            :key="item.key"
                            align="center"
                            :show-overflow-tooltip="true"
                            :resizable="false"
                          />
                        </el-table>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :prop="item.prop"
                    :label="item.label"
                    v-for="item in materialListHead"
                    :key="item.key"
                    align="center"
                    :show-overflow-tooltip="true"
                    :resizable="false"
                  />
                </el-table>
              </div>
            </div>
          </div>
          <!-- 库位列表-添加库位-物料编码dialog(一个查询条件) -->
          <div v-else-if="checkBox == '1'" id="common-dialog-checkOneBox">
            <div class="searchMaterial" id="searchMaterial">
              <div
                style="
                  border-bottom: 1px solid #ddd;
                  padding: 0 10px;
                  font-size: 14px;
                "
              >
                <el-form
                  :model="searchConditionInfo"
                  :inline="true"
                  class="demo-form-inline"
                >
                  <el-row :gutter="20">
                    <el-col
                      :span="5"
                      v-for="(item, index) in searchConditionInfo"
                      :key="index"
                      :style="{
                        padding: '0px',
                        marginLeft: '20px',
                        minWidth: '225px',
                      }"
                    >
                      <el-form-item :label="item.label + ':'">
                        <el-input
                          v-if="item.type != 'select'"
                          style="width: 100%; font-size: 14px"
                          v-model="item.value"
                          :placeholder="item.placeholder"
                          size="mini"
                        >
                        </el-input>
                        <div v-else-if="item.type == 'select'" class="selectjs">
                          <el-autocomplete
                            ref="autocomplete"
                            size="mini"
                            @change="pickSelect"
                            v-model="item.value"
                            @iconclick="sh"
                            :fetch-suggestions="querySearch"
                            value-key="label"
                            :placeholder="item.placeholder"
                            :onFocus="onFocus"
                            :onBlur="onBlur"
                            v-bind:disabled="disabled"
                            @select="pickSelect(item)"
                            @mouseleave="onmouseleave"
                            @mouseover="onmouseover(item.value)"
                            :clearable="!showershow"
                            style="font-size: 14px"
                          >
                            <template #suffix>
                              <i
                                :class="{
                                  'el-icon-arrow-up': showershow,
                                  'el-icon-arrow-down': showershow,
                                }"
                                @click="handleIconClick(item.value)"
                              ></i>
                            </template>
                          </el-autocomplete>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-button
                      class="inventorySearchButton"
                      type="primary"
                      size="mini"
                      @click="searchInfo"
                      style="
                        width: 80px;
                        height: 28px;
                        margin: 16px 10px 0px 10px;
                      "
                      >检索
                    </el-button>
                  </el-row>
                </el-form>
              </div>
              <div
                class="table_dialog"
                style="padding: 0 10px"
                v-show="PopDisplay"
              >
                <el-table
                  ref="multipleTable"
                  :data="materialListData"
                  :height="isFixedChildHeight ? tableMaterialHeight : null"
                  border
                  style="width: 100%; margin-top: 10px"
                  :empty-text="emptyMessage"
                  @selection-change="handleSelectionChange"
                  @sort-change="sortChange"
                  @select-all="selectAll"
                  @select="select"
                  stripe
                  @row-click="handleRowClickMore"
                  :header-cell-style="{ background: '#fff', color: '#23282c' }"
                  :row-class-name="tabRowClassName"
                  :highlight-current-row="isHighlightCurrentRow"
                >
                  <el-table-column
                    width="55"
                    type="selection"
                    align="center"
                    :filter-multiple="true"
                    :resizable="false"
                  ></el-table-column>
                  <el-table-column
                    :prop="item.porp"
                    :label="item.label"
                    :min-width="item.percent ? item.percent : '15%'"
                    v-for="item in materialListHead"
                    :key="item.key"
                    align="center"
                    :sortable="true"
                    :show-overflow-tooltip="true"
                    :resizable="false"
                  ></el-table-column>
                </el-table>
              </div>
              <div class="footer" v-if="PagingValue" style="margin-top: 5px">
                <span class="totalPagesTip" style="margin-top: 15px">
                  共<span class="totalPages">{{ totalnumbertitle }}</span
                  >条数据
                </span>
                <el-pagination
                  class="pagination"
                  background
                  layout="prev, pager, next"
                  page-size="100"
                  :total="totalnumbertitle"
                  hide-on-single-page
                  @current-change="continueToQuery"
                  :current-page="offset"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <!-- 供应商页面填补率弹框 -->
          <div v-else-if="supplierlistPage">
            <div class="searchMaterial" id="searchMaterial">
              <div class="table_dialog" style="padding: 0 10px">
                <el-table
                  ref="multipleTable"
                  :data="materialListData"
                  :height="isFixedChildHeight ? tableMaterialHeight : null"
                  border
                  style="width: 100%; margin-top: 10px"
                  :empty-text="emptyMessage"
                  @sort-change="sortChange"
                  @row-click="handleRowClickMore"
                  :header-cell-style="{ background: '#fff', color: '#23282c' }"
                  :row-class-name="tabRowClassName"
                  :highlight-current-row="isHighlightCurrentRow"
                >
                  <el-table-column
                    :prop="item.prop"
                    :label="item.label"
                    :min-width="item.percent ? item.percent : '15%'"
                    v-for="item in materialListHead"
                    :key="item.key"
                    align="center"
                    :sortable="true"
                    :show-overflow-tooltip="true"
                    :resizable="false"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <!-- 查询在途物料-->
          <div v-if="isShowQueryDialog">
            <div class="searchMaterial" id="searchMaterial">
              <div
                class="table_dialog"
                style="padding: 0 10px; overflow-x: scroll"
                v-show="PopDisplay"
              >
                <el-table
                  ref="multipleTable"
                  :data="materialListData"
                  :height="isFixedChildHeight ? tableChildHeight : null"
                  border
                  style="width: 100%; margin: 10px 0"
                  :empty-text="emptyMessage"
                  @selection-change="handleSelectionChange"
                  @sort-change="sortChange"
                  stripe
                  @row-click="handleRowClick"
                  :header-cell-style="{
                    background: '#fff',
                    color: '#23282c',
                  }"
                  :row-class-name="tabRowClassName"
                  :row-key="getRowKeys"
                  :highlight-current-row="isHighlightCurrentRow"
                >
                  <el-table-column
                    :prop="item.porp"
                    :label="item.label"
                    :min-width="item.percent ? item.percent : '15%'"
                    v-for="item in materialListHead"
                    :key="item.key"
                    align="center"
                    :sortable="true"
                    :show-overflow-tooltip="true"
                    :resizable="false"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isTableDialog"
          class="cu-form-group btn-item"
          :class="{
            'cu-form-group-dialog-tip': !isTableDialog,
          }"
        >
          <div v-if="oneLineOperateArea">
            <el-form
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 152px;
              "
            >
              <el-row :gutter="20">
                <el-col
                  :xs="6"
                  :sm="6"
                  :md="6"
                  :lg="6"
                  :xl="6"
                  v-for="(item, index) in showLabels"
                  :key="index"
                  :style="{
                    height: '32px',
                    margin: '5px 0',
                  }"
                >
                  <el-form-item :label="item.label" size="small">
                    <el-input
                      v-if="item.type == 'input'"
                      style="width: 200px"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      size="small"
                    ></el-input>
                    <el-input
                      v-if="item.type == 'textarea'"
                      type="textarea"
                      style="width: 300px; margin-top: -30px; font-size: 14px"
                      :autosize="{ minRows: 4, maxRows: 6 }"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      size="small"
                    ></el-input>
                    <el-select
                      v-else-if="item.type == 'select'"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      clearable
                      size="small"
                      style="font-size: 14px"
                    >
                      <!-- 此处由于修改角色直接传值角色名，所以将el-option中的:value="item.value"改成:value="item.label" -->
                      <el-option
                        v-for="item in selectValue[index].options"
                        style="width: 200px"
                        :key="item.value"
                        :value="item.label"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                    <el-date-picker
                      v-if="item.type == 'timeUpdate'"
                      v-model="item.value"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div v-else-if="isSyncData">
            <el-form
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 152px;
              "
            >
              <el-row :gutter="20">
                <el-col v-if="srReturnRemarks">
                  <el-form-item label="回收数量:" size="small">
                    <el-input
                      placeholder="请输入回收数量"
                      v-model="srReturNumber"
                      type="nu"
                      size="small"
                      @input="inputsrReturNumber"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="备注:" size="small">
                    <el-input
                      placeholder="请输入备注"
                      v-model="remarks"
                      size="small"
                      @input="inputRemarks"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col v-else>
                  <el-form-item label="合同编号：" size="small">
                    <el-input
                      placeholder="请输入需要同步的合同编号"
                      v-model="poNo"
                      size="small"
                      @input="inputContractNO"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <!-- 成本中心列表弹框（可以有多个输入项）重新写了这里先不用 -->
          <!-- <div v-show="costcenterdialog"> -->
          <!-- <el-form
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 130px;
              "
            >
              <el-row
                :gutter="20"
                v-for="(item, index) in costdialogLabels"
                :key="index"
              >
                <el-col
                  :xs="12"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                  :style="{
                    height: '32px',
                    margin: '5px 0',
                  }"
                >
                  <el-form-item :label="item.label">
                    <el-input
                      v-show="item.type === 'input'"
                      style="width: 200px"
                      :placeholder="item.placeholder"
                      :disabled="item.disabled"
                      v-model="item.value"
                      size="small"
                    ></el-input>
                    <el-select
                      v-show="item.type === 'select'"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      multiple
                      collapse-tags
                      clearable
                      size="small"
                      style="font-size: 14px"
                    >
                      <el-option
                        v-for="item in selectValue[index].options"
                        style="width: 200px"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form> -->
          <!-- </div> -->
          <div v-if="twoLineOperateArea">
            <el-form
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 152px;
              "
            >
              <el-row :gutter="20">
                <el-col
                  :xs="6"
                  :sm="6"
                  :md="6"
                  :lg="6"
                  :xl="6"
                  v-for="(item, index) in showLabels"
                  :key="index"
                  :style="{
                    height: '32px',
                    margin: '5px 0',
                  }"
                >
                  <el-form-item :label="item.label" size="small">
                    <el-input
                      v-if="item.type == 'input'"
                      style="width: 200px"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      onkeyup="this.value=this.value.match(/^\d{1,10}(\.\d{0,4})?/)?this.value.match(/^\d{1,10}(\.\d{0,4})?/)[0]:''"
                      @input="changeNum"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col
                  :xs="6"
                  :sm="6"
                  :md="6"
                  :lg="6"
                  :xl="6"
                  v-for="(item, index) in showOtherLabels"
                  :key="index"
                  :style="{
                    height: '32px',
                    margin: '5px 0',
                  }"
                >
                  <el-form-item :label="item.label" size="small">
                    <el-input
                      v-if="item.type == 'input'"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div v-else>{{ showMessage }}</div>
        </div>
        <div
          class="prompt-btn-group"
          v-if="
            (type === 'message' || type === 'tableDialog') && !supplierlistPage
          "
        >
          <el-button
            v-show="!isShowQueryDialog"
            plain
            size="small"
            class="dialogButton"
            style="margin-top: 10px"
            @click="_cancel()"
            >{{ btn_cancel }}
          </el-button>
          <el-button
            plain
            type="primary"
            size="small"
            class="dialogButton"
            style="margin-top: 10px"
            @click="_confirm()"
            >{{ btn_certain }}
          </el-button>
        </div>
      </div>
    </div>
    <!-- 移库弹框 -->
    <div
      v-show="isShowMoveDialog"
      :style="{
        position: 'fixed',
        zIndex: 10,
        background: 'rgba(209, 209, 209, 0.5)',
        left: left,
        top: top,
        height: height,
        width: width,
      }"
    >
      <div
        class="prompt-content"
        :style="{
          height:
            Outsidecontainerheight ||
            Outsidecontainerheightfour ||
            Outsidecontainerheightpaging,
        }"
        :class="{
          'prompt-content-change': isShowChooseMaterial,
          'prompt-content-table': isTableDialog,
          'prompt-condition-change': overTwoCondition,
          'promptpaging-content-change': isTableDialogpaging,
        }"
      >
        <div
          class="prompt-title"
          :class="{ 'prompt-stresstitle': stresstitle }"
        >
          {{ title }}
        </div>
        <button
          type="button"
          aria-label="Close"
          class="el-message-box__headerbtn"
          @click="_cancel()"
        >
          <i class="el-message-box__close el-icon-close"></i>
        </button>
        <div
          v-show="true"
          class="cu-form-group btn-item"
          :class="{
            'cu-form-group-dialog-tip': true,
          }"
        >
          <!-- 库存列表页面移库弹框专用 -->
          <div v-show="movedialog">
            <el-form
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 130px;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 20px;
                "
              >
                <el-radio-group v-model="radioStorage">
                  <el-radio label="01" @change="changeradio">同库房</el-radio>
                  <el-radio label="02" @change="changeradio">不同库房</el-radio>
                </el-radio-group>
              </div>
              <el-row
                :gutter="20"
                v-for="(item, index) in movedialogLabels"
                :key="index"
              >
                <el-col
                  :xs="12"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                  :style="{
                    height: '32px',
                    margin: '5px 0',
                  }"
                >
                  <el-form-item :label="item.label">
                    <el-select
                      v-show="item.type === 'moveselect'"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      clearable
                      @change="changestorge"
                      :disabled="item.disabledmove"
                      size="small"
                      style="font-size: 14px"
                    >
                      <el-option
                        v-for="item in selectValue[index].options"
                        style="width: 200px"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                    <el-input
                      v-show="item.type === 'input'"
                      style="width: 200px"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div
          class="prompt-btn-group"
          v-if="type === 'message' || type === 'tableDialog'"
        >
          <el-button
            plain
            size="small"
            class="dialogButton"
            style="margin-top: 10px"
            @click="_cancel()"
            >{{ btn_cancel }}
          </el-button>
          <el-button
            plain
            type="primary"
            size="small"
            class="dialogButton"
            style="margin-top: 10px"
            @click="_confirm()"
            >{{ btn_certain }}
          </el-button>
        </div>
      </div>
    </div>
    <!-- 成本中心弹框 -->
    <div
      v-show="costcenterdialog"
      :style="{
        position: 'fixed',
        zIndex: 10,
        background: 'rgba(209, 209, 209, 0.5)',
        left: left,
        top: top,
        height: height,
        width: width,
      }"
    >
      <div
        class="prompt-content"
        :style="{
          height:
            Outsidecontainerheight ||
            Outsidecontainerheightfour ||
            Outsidecontainerheightpaging,
        }"
        :class="{
          'prompt-content-change': isShowChooseMaterial,
          'prompt-content-table': isTableDialog,
          'prompt-condition-change': overTwoCondition,
          'promptpaging-content-change': isTableDialogpaging,
        }"
      >
        <div
          class="prompt-title"
          :class="{ 'prompt-stresstitle': stresstitle }"
        >
          {{ title }}
        </div>
        <button
          type="button"
          aria-label="Close"
          class="el-message-box__headerbtn"
          @click="_cancel()"
        >
          <i class="el-message-box__close el-icon-close"></i>
        </button>
        <div
          v-show="true"
          class="cu-form-group btn-item"
          :class="{
            'cu-form-group-dialog-tip': true,
          }"
        >
          <!-- 成本中心弹框专用 -->
          <div>
            <el-form
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 130px;
              "
            >
              <el-row
                :gutter="20"
                v-for="(item, index) in costdialogLabels"
                :key="index"
              >
                <el-col
                  :xs="12"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                  :style="{
                    height: '32px',
                    margin: '5px 0',
                  }"
                >
                  <el-form-item :label="item.label">
                    <el-input
                      v-show="item.type === 'input'"
                      style="width: 200px"
                      :placeholder="item.placeholder"
                      :disabled="item.disabled"
                      v-model="item.value"
                      size="small"
                    ></el-input>
                    <el-select
                      v-show="item.type === 'select'"
                      :placeholder="item.placeholder"
                      v-model="item.value"
                      multiple
                      collapse-tags
                      clearable
                      size="small"
                      style="font-size: 14px"
                    >
                      <el-option
                        v-for="item in selectValue[index].options"
                        style="width: 200px"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div
          class="prompt-btn-group"
          v-if="type === 'message' || type === 'tableDialog'"
        >
          <el-button
            plain
            size="small"
            class="dialogButton"
            style="margin-top: 10px"
            @click="_cancel()"
            >{{ btn_cancel }}
          </el-button>
          <el-button
            plain
            type="primary"
            size="small"
            class="dialogButton"
            style="margin-top: 10px"
            @click="_confirm()"
            >{{ btn_certain }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    PagingValue: {
      type: Boolean,
      default: false,
    },
    totalnumbertitle: {
      type: String,
      default: "",
    },
    isShowMoveDialog: {
      type: Boolean,
      default: false,
    },
    isShowDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    btn_cancel: {
      type: String,
      default: "取消",
    },
    btn_certain: {
      type: String,
      default: "确定",
    },
    // 供应商填补率弹框
    supplierlistPage: {
      type: Boolean,
      default: false,
    },
    showMessage: {
      type: String,
      default: "",
    },
    stresstitle: Boolean,
    isTableDialog: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    isShowChooseMaterial: {
      type: Boolean,
      default: false,
    },
    isTableDialogpaging: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "message",
    },
    searchConditionInfo: Array,
    materialListData: Array,
    isFixedChildHeight: {
      type: Boolean,
      default: false,
    },
    tableChildHeight: {
      type: String,
      default: "210px",
    },
    tableMaterialHeight: {
      type: String,
      default: "212px",
    },
    materialListHead: Array,
    receivingRecordData: Array,
    receivingRecordHead: Array,
    materialListInfoData: Array,
    materialListInfoHead: Array,
    isBack: {
      type: Boolean,
      default: false,
    },
    oneLineOperateArea: {
      type: Boolean,
      default: false,
    },
    createShutDown: {
      type: Boolean,
      default: false,
    },
    // 成本中心多个输入项
    costcenterdialog: {
      type: Boolean,
      default: false,
    },
    twoLineOperateArea: {
      type: Boolean,
      default: false,
    },
    //移库弹框
    movedialog: {
      type: Boolean,
      default: false,
    },
    isSyncData: {
      type: Boolean,
      default: false,
    },
    // 以旧换新弹框
    srReturnRemarks: {
      type: Boolean,
      default: false,
    },

    srReturnQuantity: {
      type: Number,
      default: 0,
    },

    createNumber: {
      type: Boolean,
      default: false,
    },
    //移库弹框移库方式
    radioSelectStorage: String,
    //移库弹框lable名
    movedialogLabels: Object,
    // 成本中心多个输入项的lable
    costdialogLabels: Object,
    showLabels: Object,
    showOtherLabels: Object,
    selectValue: Object,
    dataIndex: Number,
    overTwoCondition: {
      type: Boolean,
      default: false,
    },
    checkBox: {
      type: String,
      default: "1+",
    },
    isChangeStyle: {
      type: Boolean,
      default: false,
    },
    emptyTip: {
      type: String,
      default: "",
    },
    crossFactorySearch: {
      type: Boolean,
      default: false,
    },
    PopDisplay: {
      type: Boolean,
      default: true,
    },
    downOptions: Array,
    showPageSelect: {
      type: Boolean,
      default: false,
    },
    showPageSelectData: Array,
    initClear: {
      type: Boolean,
      default: false,
    },
    currentOffset: {
      type: Number,
      default: 1,
    },
    // 外容器的高度
    Outsidecontainerheight: {
      type: String,
      default: "",
    },
    // 搜索条件是4个的 外容器的高度
    Outsidecontainerheightfour: {
      type: String,
      default: "",
    },
    // 带分页的 外容器的高度
    Outsidecontainerheightpaging: {
      type: String,
      default: "",
    },
    // 按钮底部的高度
    btnbottomheight: {
      type: String,
      default: "",
    },
    // 四个检索条件的按钮底部的高度
    btnbottomheightfour: {
      type: String,
      default: "",
    },
    // 带分页的按钮底部的高度
    btnbottomheightpaging: {
      type: String,
      default: "",
    },
    zeroSearchFlg: {
      type: Boolean,
      default: false,
    },
    isShowQueryDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //移库弹框 移库方式默认同库房
      radioStorage: "01",
      //移库弹框 下拉框禁用
      disabledmove: true,
      disabled: false,
      showershow: true,
      searchMaterialCondition: {
        materialID: "",
        materialName: "",
      },
      totalnumber: 0,
      RoleName: "",
      poNo: "",
      remarks: "", //以旧换新备注
      srReturNumber: "", //以旧换新回收数量
      // srReturNumberErrorMessages:null,//以旧换新回收数量的错误信息
      width: "100vh",
      left: "0px",
      top: "0px",
      emptyMessage: " ",
      height: "100vh",
      screenWidth: "",
      collapserepeat: this.$store.state.collapse,
      screenHidth: "",
      showicon: true,
      offset: 1,
      clearable: false,
      isHighlightCurrentRow: true,
    };
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
  },
  watch: {
    collapse(e) {
      if (!e) {
        if (!this.timerflag) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.timerflag = true;
          let that = this;
          setTimeout(function () {
            that.timerflag = false;
            that.screenWidth = document.body.clientWidth;
            that.screenHidth = document.body.clientHeight;
            that.changeDialogDisStyle(that.screenWidth, that.screenHidth);
          }, 330);
        }
      } else {
        this.screenWidth = document.body.clientWidth;
        this.screenHidth = document.body.clientHeight;
        this.changeDialogDisStyle(this.screenWidth, this.screenHidth);
      }
    },
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.screenWidth = val;
          that.timer = false;
        }, 400);
      }
    },
    materialListData: {
      deep: true,
      handler: function (value) {
        if (value.length !== 0) {
          this.$nextTick(function () {
            this.closeLoading();
          });
        } else {
          if (this.zeroSearchFlg) {
            this.closeLoading();
          }
        }
      },
    },
    receivingRecordData: {
      deep: true,
      handler: function (value) {
        if (value.length !== 0) {
          this.$nextTick(function () {
            this.closeLoading();
          });
        } else {
          if (this.zeroSearchFlg) {
            this.closeLoading();
          }
        }
      },
    },
  },
  mounted() {
    console.log("materialListData", this.materialListData);
    if (this.emptyTip != "") {
      this.emptyMessage = this.emptyTip;
    }
    if (this.radioSelectStorage != "") {
      this.radioStorage = this.radioSelectStorage;
    }
    if (this.totalnumbertitle != "") {
      this.totalnumber = this.totalnumbertitle;
    }
    if (this.PagingValue != "") {
      this.Paging = this.PagingValue;
    }
    this.screenWidth = document.body.clientWidth;
    this.screenHidth = document.body.clientHeight;
    if (this.screenWidth <= 1280) {
      this.screenWidth = 1280;
    }
    if (this.screenHidth <= 520) {
      this.screenHidth = 520;
    }
    this.changeDialogDisStyle(this.screenWidth, this.screenHidth);
    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        this.screenHidth = document.body.clientHeight;
        if (this.screenWidth <= 1280) {
          this.screenWidth = 1280;
        }
        if (this.screenHidth <= 520) {
          this.screenHidth = 520;
        }
        this.changeDialogDisStyle(this.screenWidth, this.screenHidth);
      },
      false
    );
    if (this.currentOffset) {
      this.offset = this.currentOffset;
    }
  },
  updated() {
    if (this.showPageSelect) {
      if (this.showPageSelectData.length === 0) {
        if (this.materialListData.length !== 0) {
          this.$refs.multipleTable.clearSelection();
        }
      } else {
        this.showPageSelectData.map((item) => {
          let isFind = this.materialListData.find(
            (ff) => ff.INV_ITEM_Code === item.INV_ITEM_Code
          );
          if (isFind != undefined) {
            this.$refs.multipleTable.toggleRowSelection(isFind, true);
          } else {
            this.$refs.multipleTable.clearSelection();
          }
        });
      }
    } else {
      if (this.initClear) {
        this.$refs.multipleTable.clearSelection();
      }
    }
    if (this.emptyTip != "") {
      this.emptyMessage = this.emptyTip;
    }
    if (this.totalnumbertitle != "") {
      this.totalnumber = this.totalnumbertitle;
    }
    if (this.PagingValue != "") {
      this.Paging = this.PagingValue;
    }
    if (this.isSyncData) {
      const searchInfo = this.$store.state.searchInfo;
      let isPoNoData = true;
      searchInfo.map((item) => {
        if (item.routerName === "contractlist" && item.searchData) {
          this.poNo = item.searchData.syncPoNo;
          isPoNoData = false;
        }
      });
      if (isPoNoData) {
        this.poNo = "";
      }
    }
    if (this.currentOffset) {
      this.offset = this.currentOffset;
    }
    if (this.radioSelectStorage != "") {
      this.radioStorage = this.radioSelectStorage;
    }
    // 以旧换新弹框：打开的时候，输入项目绑定的值清空
    if (this.srReturnRemarks) {
      this.srReturNumber = null;
      this.remarks = null;
    }
  },
  methods: {
    //移库弹框 移库方式radio的change事件
    changeradio() {
      this.$emit("changeradio", this.radioStorage);
    },
    //移库弹框 库房下拉框的change事件
    changestorge(data) {
      this.$emit("changestorge", data);
    },
    // 获取焦点时，不可输入，属性设为true
    onFocus() {
      this.disabled = true;
    },
    // 失去焦点时，可输入，属性设为false
    onBlur() {
      this.disabled = false;
    },
    // 鼠标悬浮时不显示箭头图标
    mouseOverAutoComplete(value) {
      if (value) {
        this.clearable = true;
        this.showicon = false;
      } else {
        this.clearable = false;
      }
    },
    mouseLeaveAutoComplete(value) {
      this.showicon = true;
      console.log(value);
    },
    // 鼠标悬浮时不显示箭头图标
    mouseDownAutoComplete(value) {
      if (value) {
        this.showicon = false;
      }
    },
    getRowKeys(row) {
      return row.id;
    },
    continueToQuery(e) {
      this.offset = e;
      this.$emit("searchPageInfo", this.offset);
    },
    changeDialogDisStyle(width, height) {
      if (this.collapse) {
        this.top = "90px";
        this.left = "64px";
        this.height = height - 90 + "px";
        this.width = width - 65 + "px";
      } else {
        this.top = "90px";
        this.left = "250px";
        this.width = width - 250 + "px";
        this.height = height - 90 + "px";
      }
    },
    _cancel() {
      this.$emit("closeDialog", false);
    },
    _confirm() {
      if (this.oneLineOperateArea) {
        if (this.title === "添加角色") {
          this.$emit("confirmAction", this.showLabels[0].value);
        } else {
          this.$emit("confirmAction", {
            dataIndex: this.dataIndex,
            RoleName: this.showLabels[0].value,
          });
        }
      } else if (this.isSyncData) {
        this.$emit("confirmAction", this.poNo);
      } else {
        if (this.title === "删除") {
          this.$emit("confirmAction", this.dataIndex);
        } else if (this.title === "选择物料") {
          this.$emit("confirmMaterialChoose", "");
        } else if (this.title === "选择收货记录") {
          this.$emit("chooseReceiverRecord", "");
        } else {
          this.$emit("confirmAction", false);
        }
      }
    },
    searchInfo() {
      this.$emit("searchInfo", this.searchMaterialCondition);
    },
    crossFactorySearchInfo() {
      this.$emit("crossFactorySearchInfo", this.searchMaterialCondition);
    },
    chooseReceiverRecord() {
      this.$emit("chooseReceiverRecord", "");
    },
    _back() {
      this.$emit("back");
    },
    handleSelectionChange(data) {
      this.$emit("selectData", data);
    },
    sortChange(column) {
      this.$emit("sortChange", column);
    },
    selectAll() {
      this.$refs.multipleTable.clearSelection();
    },
    select(selection) {
      if (selection.length > 1) {
        let del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleRowClickMore(row) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    inputContractNO() {
      this.$emit("inputContractNO", this.poNo);
    },
    // 以旧换新备注输入
    inputRemarks() {
      this.$emit("inputRemarks", this.remarks);
    },
    // 以旧换新：回收数量输入事件
    inputsrReturNumber() {
      this.srReturNumber = this.srReturNumber.match(/^\d{1,10}(\.\d{0,4})?/)
        ? this.srReturNumber.match(/^\d{1,10}(\.\d{0,4})?/)[0]
        : "";
      this.$emit("inputsrReturNumber", this.srReturNumber);
    },
    changeNum() {
      this.$emit("changeNum");
    },
    // 表格斑马线
    tabRowClassName(row) {
      let addNum = 15 * (this.offset - 1);
      row.row.id = row.rowIndex + addNum;
      if (row.rowIndex % 2 == 0) {
        return "tabRowClassName";
      } else {
        return "";
      }
    },
    // 点击图标也可出输入建议
    handleIconClick(val) {
      this.$refs.autocomplete.activated = true;
      this.searchKey = val;
      this.$refs.autocomplete.getData(val);
    },
    querySearch(queryString, cb) {
      var restaurants = this.selectValue;
      // 调用 callback 返回建议列表的数据
      cb(restaurants);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    pickSelect(data) {
      this.showicon = true;
      this.$emit("pickSelect", data);
    },
    closeLoading() {
      this.$emit("closeLoading", false);
    },
    onmouseleave() {
      this.showershow = true;
    },
    onmouseover(val) {
      if (val) {
        this.showershow = false;
      } else {
        this.showershow = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cu-form-group {
  text-align: center;
}
.cu-form-group-dialog-tip {
  height: 150px;
  line-height: 150px;
}
.prompt-content {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  width: 450px;
  box-sizing: bordre-box;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: #fff;
  color: rgb(136, 136, 136);
  font-size: 16px;
  border-radius: 10px;
  box-shadow: -5px 5px 25px #888888;
}

.prompt-content-table {
  width: 70%;
  min-width: 1000px;
}

.prompt-content-change {
  height: auto;
  height: 530px;
}
.promptpaging-content-change {
  height: auto;
  height: 450px;
}
.prompt-condition-change {
  width: 80%;
  min-width: 1100px;
}

.prompt-title {
  padding: 10px 0;
  font-size: 24px;
  color: #409eff;
  font-weight: bold;
  margin-left: 10px;
}
.prompt-stresstitle {
  color: red;
}

.prompt-btn-group {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.btn-item {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.prompt-cancel-btn {
  border-right: 1px solid #ccc;
}
.dialogFooter {
  float: right;
  margin: 0 89px 10px 0;
}

.dialogButton {
  width: 80px;
}

.dialog-back {
  float: left;
  font-size: 16px;
  color: #409eff;
  padding: 3.5px 0 0px 19px;
  cursor: pointer;
}

.el-select {
  width: 200px;
}
// 不带分页 查询的表格
.btn-item-MaterialDialog {
  height: 280px;
}
// 带分页 的
.btn-item-MaterialDialogpaging {
  height: 315px;
}

.btn-item-not-Messagedialog {
  height: 330px;
}

.el-table /deep/ th {
  padding: 5px 0;
  min-height: 39px;
  height: 39px;
}
.el-table /deep/ td {
  padding: 5px 0;
  min-height: 39px;
  height: 39px;
}
#CommonDialog .el-form-item__label {
  text-align: left;
}
.el-table td,
.el-table th {
  padding: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
}
#common-dialog-checkOneBox .el-table__header-wrapper .el-checkbox {
  display: none;
}
#CommonDialog .el-message-box__headerbtn {
  right: 10px !important;
}

#CommonDialog .el-row {
  margin-bottom: 0 !important;
}

.el-table thead {
  color: #23282c !important;
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td {
  // background: rgba(0, 0, 0, 0.05) !important;
  background: #fff !important;
}
.el-table--border,
.el-table--group {
  border-top: 1px solid #ddd !important;
  border-left: 1px solid #ddd !important;
  // border-right: 1px solid #ddd !important;
}
/deep/ .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #ddd !important;
}
/deep/ .el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #ddd !important;
}
.el-table {
  color: #23282c !important;
}
.el-table .descending .sort-caret.descending {
  border-top-color: #fff !important;
}
.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #fff !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -o-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}
#dialog-table-in-table .el-table--border,
.el-table--group {
  border: none !important;
  border-left: 1px solid #ddd !important;
}
.el-form-item__content {
  width: 100% !important;
}
.el-select--mini {
  width: 100% !important;
}
.el-table /deep/ th {
  padding: 1px 0;
  min-height: 30px;
  height: 30px;
}
.el-table /deep/ td {
  padding: 1px 0;
  min-height: 30px;
  height: 30px;
}
/deep/.el-table .tabRowClassName {
  background-color: rgba(0, 0, 0, 0.05) !important;
  /* background: #30659b; */
}
//表格th--border
/deep/ .el-table--border th,
.el-table__fixed-right-patch {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd !important;
}

.selectjs {
  .selectjs:hover {
    cursor: text !important;
  }
  .selectjs_min {
    height: 28px !important;
    line-height: 28px !important;
    background-color: #fff;
    border-radius: 4px !important;
    border: 1px solid #dcdfe6;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: text;
  }
  .selectjs_min:hover {
    border: 1px solid #bdbdbe !important;
  }
}
.selectjs:after {
  content: "";
  width: 14px;
  height: 8px;
  // background: url(../assets/arrow-down.png) no-repeat center;
  /*通过定位将图标放在合适的位置*/
  position: absolute;
  top: 0;
  // right: 20px;
  // top: 41%;
  /*给自定义的图标实现点击下来功能*/
  pointer-events: none;
}
.selectjs select {
  border: none;
  /*清除select聚焦时候的边框颜色*/
  outline: none;
  /*将select的宽高等于div的宽高*/
  width: 100%;
  height: 40px;
  line-height: 40px;
  /*隐藏select的下拉图标*/
  // appearance: none;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  /*通过padding-left的值让文字居中*/
  padding-left: 20px;
}
/deep/.el-autocomplete-suggestion__wrap {
  max-height: 121px !important;
}
/deep/.el-input--mini .el-input__inner {
  min-width: 80px;
}
/*隐藏滚动条*/
/deep/::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bfbfbf;
}
/deep/::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bfbfbf;
}
/deep/::-webkit-scrollbar-thumb:hover {
  background-color: #333 !important;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #333 !important;
}
/deep/ .el-table__header .cell {
  padding: 0 !important;
  text-overflow: unset !important;
  white-space: nowrap !important;
}
.el-form-item {
  margin-top: 10px;
  margin-bottom: 10px;
}
/deep/ .el-input--mini .el-input__inner {
  min-width: 130px;
}
/deep/ .el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  padding-left: 14px;
}
// table选中行高亮样式设置
/deep/.el-table__body tr.current-row > td {
  background: #ffffcc !important;
}
</style>
