<template>
  <div>
    <div class="title" v-if="title">{{ title }}</div>
    <div class="title-btn" v-if="btn">
      <el-button
        type="primary"
        size="mini"
        v-for="(item, index) in btn"
        :key="index"
        @click="doSth(index, item)"
      >
        {{ item.label }}
      </el-button>
    </div>
    <div class="body" v-if="information">
      <div v-if="!typeOfSelect">
        <div v-if="typeOfRadio">
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="(item, index) in info"
              :key="index"
              class="body-col"
            >
              <el-radio
                @change="getQueryTerms"
                v-model="radio"
                :label="item.value"
                >{{ item.name }}</el-radio
              >
            </el-col>
          </el-row>
        </div>
        <div v-if="!typeOfRadio">
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="(item, index) in info"
              :key="index"
              class="body-col"
            >
              <div class="body-text">{{ item.name }}：{{ item.value }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="typeOfSelect">
        <el-row :gutter="20">
          <el-col
            :span="8"
            v-for="(item, index) in info"
            :key="index"
            class="body-col"
          >
            <div>
              <el-row :gutter="20">
                <el-col :span="11" v-if="item.name.length > 8">
                  <div class="body-col-select-left">{{ item.name }}：</div>
                </el-col>
                <el-col
                  :span="6"
                  v-if="item.name.length < 9 && item.name.length > 2"
                >
                  <div class="body-col-select-left">{{ item.name }}：</div>
                </el-col>
                <el-col :span="4" v-if="item.name.length < 3">
                  <div class="body-col-select-left">{{ item.name }}：</div>
                </el-col>
                <el-col :span="12">
                  <div class="body-col-select-right" id="body-col-select-right">
                    <el-select
                      v-model="selectData[index].value"
                      size="mini"
                      @change="getQueryTerms"
                    >
                      <el-option
                        v-for="selectItem in selectData[index].options"
                        :key="selectItem.value"
                        :label="selectItem.label"
                        :value="selectItem.value"
                        :placeholder="selectItem.label"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.selectData = this.typeOfSelect;
    this.info = this.information;
  },
  props: ["title", "information", "typeOfSelect", "typeOfRadio", "btn"],
  data() {
    return {
      radio: "1",
      selectData: null,
    };
  },
  methods: {
    doSth(index, data) {
      this.$emit("doSth", {
        index: index,
        data: data,
      });
    },
    getQueryTerms() {
      if (this.typeOfSelect) {
        this.$emit("getQueryTerms", this.selectData);
      }
      if (this.information) {
        this.$emit("getQueryTerms", { info: this.info, index: this.radio });
      }
    },
  },
  mounted() {},
};
</script>

<style>
.title {
  line-height: 40px;
  font-weight: bolder;
  border-bottom: 1px solid #409eff;
}
.body-col {
  margin: 10px 0;
}
.body-text {
  float: left;
  display: inline;
  font-size: 14px;
}
.body-col-select-left {
  display: inline;
  width: 120px;
  line-height: 27px;
}
.body-col-select-right {
  width: 150px;
  line-height: 27px;
}
.title-btn {
  margin: -40px 0 0 0;
  text-align: right;
}
#body-col-select-right .el-input__inner {
  width: 200px;
}
</style>
