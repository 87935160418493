import { ElLoading } from "element-plus";

let loadingCount = 0;
let loading;

const startLoading = (targetdq) => {
  loading = ElLoading.service({
    text: "数据加载中...",
    background: "rgba(209, 209, 209, 0.5)",
    target: document.querySelector(targetdq)
  });
};

const endLoading = () => {
  loading.close();
};

export const showLoading = (targetdq) => {
  if (loadingCount === 0) {
    startLoading(targetdq);
  }
  loadingCount += 1;
};

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount -= 1;
  if (loadingCount === 0) {
    endLoading();
  }
};

export default {
  showLoading,
  hideLoading
}