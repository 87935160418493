<template>
  <div
    class="common-upload"
    id="common-upload"
  >
    <div class="common-upload-upload-upload">
      <div v-if="noticechange">
       
        <el-input
          v-if="AttachMing ==='' && !contralinput"
          v-model="inputText"
          :placeholder="inputText"
        >
        </el-input>
        <el-input
          v-if="AttachMing !=='' && contralinput"
          v-model="inputText"
          :placeholder="inputText"
        >
        </el-input>
         <el-input
          v-if="AttachMing ==='' && contralinput"
          v-model="inputText"
          :placeholder="inputText"
        >
        </el-input>
      </div>
      <div v-if="!noticechange">
        <span
          v-if="PickingMaterial"
          style="color:red;"
        >{{"注意：只能导入前15条数据"}}</span>
        <el-input
          v-model="inputText"
          :placeholder="inputText"
        >
        </el-input>
      </div>
      <div>
        <el-input
          v-if="AttachMing !='' && !contralinput"
          v-model="showAttachMing"
          style="color:blue"
          class="hoverDownFile"
          @click="downLoadFile"
        >
        </el-input>
      </div>
    </div>
    <div class="common-upload-upload-choose">
      <el-upload
        ref="upload"
        class="upload-demo"
        :action="actionUrl"
        :headers="headers"
        :on-change="chooseFile"
        :show-file-list="false"
        :on-success="isSuccess"
        :file-list="fileList"
        :http-request="modeUpload"
      >
        <el-button
          type="primary"
          @click="clearFile"
        > 上传文件</el-button>
      </el-upload>
      <el-button
        v-if="exportInventorButton"
        type="primary"
        style="margin-left:10px;height:26px !important; position: relative;top: -32px;right: -70px;margin-bottom: 0px"
        @click="exporInventory"
      >导出</el-button>
      <el-button v-if="offlineReceiving" type="success" size="mini"  style="margin-left:10px;height:26px !important; position: relative;top: -32px;right: -70px;margin-bottom: 0px"
        @click="downloadTemplate">
				下载模板
			</el-button>
    </div>
  </div>
</template>
<script>
// import request from "../utils/request";
export default {
  props: {
    AttachMing: {
      type: String,
      default: "",
    },
    noticechange: {
      type: String,
      default: "",
    },
    NoticeNo: {
      type: Boolean,
      default: false,
    },
    PickingMaterial: {
      type: Boolean,
      default: false,
    },
    // 盘点计划盘点的导出按钮
    exportInventorButton:{
      type: Boolean,
      default: false,
    },
    // 线下收货下载模板按钮
    offlineReceiving:{
      type: Boolean,
      default: false,
    },
  },
  data () {
    let baseUrl =
      process.env.NODE_ENV == "development"
        ? "http://39.100.4.66:8002"
        : "http://39.100.4.66:8002";
    let token = null;
    if (sessionStorage.getItem("token")) {
      token = sessionStorage.getItem("token");
    }
    return {
      actionUrl: baseUrl + "/api/StorageLocation/Import",
      headers: {
        token,
      },
      inputText: "请选择文件",
      fileList: [],
      mode: {},
      contralinput: false,
      showAttachMing: ""
    };
  },
  mounted () {
    console.log("AttachMing", this.AttachMing);
    console.log("this.contralinput", this.contralinput);
    console.log("noticechange", this.noticechange);
    if (this.AttachMing !== "") {
      this.showAttachMing = this.AttachMing;
    }
  },
  updated () {
    console.log("updated---AttachMing", this.AttachMing);
    if (this.AttachMing !== "") {
      this.showAttachMing = this.AttachMing;
    }
    console.log("updated---AttachMingafter", this.AttachMing);
    console.log("showAttachMing", this.showAttachMing);
  },
  methods: {
    //公告点击下载事件
    downLoadFile () {
      console.log("点击下载附件啦");
      this.$emit("downLoadFile")
    },
    //公告上传事件
    releaseNoticeInfo () {
      console.log("文件上传11111")
      this.$emit("releaseNoticeInfo")
    },
    modeUpload (item) {
      console.log("文件上传22222item.file",item.file);
      console.log("文件上传33333item",item);
      this.mode = item.file;
      this.$emit("uploadFile", this.mode);
    },
    clearFile () {
      console.log("aaaa");
      this.fileList = [];
    },
    // 盘点计划盘点的导出
    exporInventory(){
      this.$emit("exporInventory")
    },
    //线下收货的模板下载
    downloadTemplate(){
      this.$emit("downloadTemplate")
    },
    chooseFile (file) {
      this.fileList.push(file);
      this.inputText = file.name;
      this.contralinput = true;
      this.$emit("changeFileName",this.inputText)
    },
    submit () {
      this.$refs.upload.submit();
    },
    isSuccess (response) {
      if (response.IsSuccess) {
        this.$message({
          message: response.Message,
          type: "success",
        });
      } else {
        this.$message({
          message: response.Message,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-upload--text {
  width: 80px;
  height: 32px;
  border: none;
  border-radius: 0;
}
.el-upload-list {
  width: 30%;
}
.common-upload-upload-upload {
  position: relative;
  width: 25%;
  top: 33px;
  left: 0%;
}
#common-upload .el-button {
  min-height: 16px !important;
  padding: 6px 10px !important;
}
#common-upload .el-input__inner {
  height: 28px !important;
}
.common-upload-upload-choose {
  position: relative;
  top: 0;
  left: 27%;
  width: 100px;
}
.common-upload {
  margin-top: -25px;
}
</style>
