import axios from "axios";
import { ElMessage } from "element-plus";

const service = axios.create({
  // 正式包 域名
  // baseURL:
  //   process.env.NODE_ENV == "development"
  //     ? "http://19.204.1.223:8081/api"
  //     : "http://19.204.1.223:8081/api",
  // 本地测试环境
  baseURL:
    process.env.NODE_ENV == "development"
      ? "http://39.100.4.66:8002/api/"
      : "http://39.100.4.66:8002/api/",
  // 客户那边测试包
  // baseURL:
  //   process.env.NODE_ENV == "development"
  //     ? "http://19.250.106.62:8091/api/"
  //     : "http://19.250.106.62:8091/api/",
  // baseURL:
  //     process.env.NODE_ENV == "development"
  //     ? "http://localhost:8089/api/"
  //     : "http://localhost:8089/api/",
  headers: { "Content-Type": "application/json" },
  timeout: 1800000,
});

service.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem("token")) {
      config.headers["token"] = sessionStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response && response.headers && response.headers.authorization) {
      sessionStorage.setItem("token", response.headers.authorization);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 请求成功的回调
function checkStatus(res) {
  // 请求结束成功
  if (res.status === 200) {
    return res;
  } else {
    ElMessage.error("error");
  }
}
// 请求成功的回调（不可用无聊处置详情页面下载附件专用：status=400时直接报没有附件）
function checkStatusUnavailableItem(res) {
  // 请求结束成功
  if (res.status === 200) {
    return res;
  } else if (res.status === 400) {
    ElMessage.error("该申请单没有附件！");
  } else {
    ElMessage.error("下载失败！");
  }
}

// 模块化导出
export default {
  get(url, data) {
    if (!url) return;
    return service({
      method: "get",
      url: url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    })
      .then(checkStatus)
      .catch(function (error) {
        console.log("error", error);
        ElMessage.error("error");
      });
  },

  post(url, data) {
    if (!url) return;
    return service({
      method: "post",
      url: url,
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    })
      .then(checkStatus)
      .catch(function (error) {
        console.log("error", error);
        ElMessage.error("error");
      });
  },

  postFileDownload(url, data) {
    if (!url) return;
    return service({
      method: "post",
      url: url,
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    })
      .then(checkStatus)
      .catch(function (error) {
        console.log("error", error);
        ElMessage.error("error");
      });
  },

  // （不可用无聊处置详情页面下载附件专用：status=400时直接报没有附件）
  postFileDownloadUnavailableItem(url, data) {
    if (!url) return;
    return service({
      method: "post",
      url: url,
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    })
      .then(checkStatusUnavailableItem)
      .catch(function (error) {
        console.log("error", error);
        ElMessage.error("下载失败");
      });
  },

  postBodyFileDownload(url, data) {
    if (!url) return;
    return service({
      method: "post",
      url: url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    })
      .then(checkStatus)
      .catch(function (error) {
        console.log("error", error);
        ElMessage.error("error");
      });
  },

  submit(url, data) {
    if (!url) return;
    return service({
      method: "post",
      url: url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    })
      .then(checkStatus)
      .catch(function (error) {
        console.log("error", error);
        ElMessage.error("error");
      });
  },

  postFormdata(url, data) {
    if (!url) return;
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return service({
      method: "post",
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    })
      .then(checkStatus)
      .catch(function (error) {
        console.log("error", error);
        ElMessage.error("error");
      });
  },
};
