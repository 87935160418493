<template>
  <div class="header">
    <div class="header-img-system-logo">
      <div
        :class="isCollapseChage ? 'imgCollapseChage' : 'imgNotCollapseChage'">
        <img
          src="../assets/img/logo_ford_login.png"
          alt=""
          style="width: 64px; height: 40px"
        />
      </div>

      <div
        :class="isCollapseChage ? 'isCollapseBtn' : 'notCollapseBtn'"
        @click="collapseChage"
      >
        <i
          v-if="!collapse"
          class="el-icon-s-fold"
        ></i>
        <i
          v-else
          class="el-icon-s-unfold"
        ></i>
      </div>
    </div>
    <div
      :class="isCollapseChage ? 'logoCollapseChage' : 'logoNotCollapseChage'">
      非生产存货物料管理系统
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <el-dropdown
          class="user-name"
          trigger="click"
          @command="handleCommand"
        >
          <div class="user-login">
            <span class="el-dropdown-link userSet">
              {{ loginUserInfo.accountName }}
            </span>
            <div class="user-address">
              {{ loginUserInfo.factoryName }}/{{ loginUserInfo.roleName }}
              <i class="el-icon-caret-bottom"></i>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu style="padding: 0">
              <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isCollapseChage: this.$store.state.collapse,
    };
  },
  computed: {
    loginUserInfo () {
      const loginUserInfo = JSON.parse(sessionStorage.getItem("loginUserInfo"));
      return loginUserInfo;
    },
    collapse () {
      return this.$store.state.collapse;
    },
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand (command) {
      if (command == "loginout") {
        sessionStorage.removeItem("loginUserInfo");
        sessionStorage.removeItem("menuInfo");
        sessionStorage.removeItem("operateInfo");
        sessionStorage.removeItem("collapse");
        sessionStorage.removeItem("token");
        this.$store.commit("clearStore");
        this.$router.push("/login");
      }
    },
    // 侧边栏折叠
    collapseChage () {
      this.$store.commit("hadndleCollapse", !this.collapse);
      this.$emit("getLastRouter", this.$route.name);
      this.isCollapseChage = this.collapse;
    },
  },
  mounted () {
    window.addEventListener(
      "resize",
      () => {
        if (document.body.clientWidth <= 1280) {
          this.$store.commit("hadndleCollapse", true);
          this.$emit("getLastRouter", this.$route.name);
          this.isCollapseChage = true;
        }
      },
      false
    );
    document.onkeydown = function (event) {
      var e = event || window.event || arguments.callee.caller.arguments[0];
      if (e && e.keyCode == 122) {
        this.isFullScreen = false;
      }
    };
  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 55px;
  font-size: 22px;
  color: #000;
  background: rgb(255, 255, 255);
  /* border-bottom: 1px solid #c8ced3; */
}
.isCollapseBtn {
  position: absolute;
  left: 68px;
  top: 12px;
  cursor: pointer;
}
.notCollapseBtn {
  position: absolute;
  left: 154px;
  top: 13px;
  cursor: pointer;
}
.header .imgCollapseChage {
  margin-top: 10px;
}
.header .imgNotCollapseChage {
  margin-top: 10px;
  margin-left: 54px;
}
.header .logoCollapseChage {
  float: left;
  line-height: 55px;
  margin-left: 104px;
  font-size: 30px;
  font-weight: bold;
}
.header .logoNotCollapseChage {
  float: left;
  line-height: 55px;
  margin-left: 250px;
  font-size: 30px;
  font-weight: bold;
}
.header-right {
  float: right;
}
.header-user-con {
  display: flex;
  height: 55px;
  align-items: center;
  margin-right: 10px;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.el-dropdown-link {
  color: #000;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}
.user-address {
  color: #aaa6a6;
  font-size: 12px;
  cursor: pointer;
}
.user-login {
  margin-top: 10px;
  margin-left: 10px;
}
.userSet {
  font-size: 14px;
  font-weight: bold;
}
.collapse-btn:hover {
  background: none;
  line-height: 55px;
}
.header-img-system-logo {
  position: absolute;
  /* top: 8px;
  left: 10px; */
}
.el-icon-caret-bottom {
  font-size: 14px;
  color: #000;
}
</style>
