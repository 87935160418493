<template>
  <div ref="tages">
    <div class="tags" v-if="showTags">
      <el-tabs
        closable
        @tab-remove="removeTab"
        @tab-click="isActive"
        v-model="isChoosed"
        :before-leave="uidshfids"
      >
        <el-tab-pane
          ref="tagesiteam"
          v-for="(item, index) in tagsList"
          :key="index"
          :label="item.title"
          :name="item.name"
        ></el-tab-pane>
      </el-tabs>
      <div class="tags-close-box" style="cursor: pointer">
        <el-dropdown
          type="primary"
          @command="handleTags"
          trigger="click"
          class="wanziqiangsfl"
          placement="bottom-end"
        >
          <span class="el-dropdown-link">
            <i
              class="el-icon-arrow-down el-icon--right"
              style="margin-left: -1px"
            ></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu size="small">
              <el-dropdown-item command="other">关闭其他</el-dropdown-item>
              <el-dropdown-item command="all">关闭所有</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from '@/store'
export default {
  data() {
    return {
      tagswidth: 180,
      tagsnubmber: this.$store.state.tagsList.length,
      flag: true,
      tagewidth: 0,
    };
  },
  computed: {
    // ...mapState(['tagsList']),
    tagsList() {
      return this.$store.state.tagsList;
    },
    showTags() {
      return this.tagsList.length > 0;
    },
    isChoosed() {
      if (
        this.tagsList.some((item) => item.name === this.$route.meta.parentName)
      ) {
        return this.$route.meta.parentName;
      } else {
        return this.$route.name;
      }
    },
    // 计算tag 宽度
  },
  methods: {
    removeTab(targetName) {
      let tagIndex = 0;
      this.tagsList.map((item, index) => {
        if (item.name === targetName) {
          tagIndex = index;
        }
      });
      this.closeTags(tagIndex);
    },
    isActive(path) {
      this.$router.push({ name: path.props.name });
      this.$emit("getLastRouter", path.props.name);
    },
    closeTags(index) {
      const delItem = this.tagsList[index];
      if (this.tagsList.length === 1 && delItem.name === "dashboard") {
        return;
      }
      this.$store.commit("delTagsItem", { index, name: delItem.name });
      const item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.$route.fullPath && this.$router.push(item.path);
        if (this.$route.meta.parentPath) {
          delItem.path === this.$route.meta.parentPath &&
            this.$router.push(item.path);
        }
      } else {
        this.$router.push("/");
      }
    },
    closeAll() {
      if (this.tagsList.length === 1 && this.tagsList[0].name === "dashboard") {
        return;
      }
      if (this.tagsList.length > 1 && this.$route.fullPath === "/dashboard") {
        return this.closeOther();
      }
      this.$store.commit("clearTags");
      this.$router.push("/");
    },
    closeOther() {
      const curItem = this.tagsList.filter((item) => {
        return item.path === this.$route.fullPath;
      });
      this.$store.commit("closeTagsOther", curItem);
    },
    setTags(route) {
      const isExist = this.tagsList.some((item) => {
        return item.path === route.fullPath;
      });
      if (!isExist) {
        if (route.meta.parentPath) {
          if (
            this.tagsList.some((item) => item.name === route.meta.parentName)
          ) {
            this.tagsList.map((query) => {
              if (query.name === route.meta.parentName) {
                query.path = route.fullPath;
              }
            });
          }
        } else {
          if (this.tagsList.some((item) => item.name === route.name)) {
            this.tagsList.map((query) => {
              if (query.name === route.name) {
                query.path = route.fullPath;
              }
            });
          } else {
            this.$store.commit("setTagsItem", {
              name: route.name,
              title: route.meta.title,
              path: route.fullPath,
            });
          }
        }
      }
    },
    handleTags(command) {
      command === "other" ? this.closeOther() : this.closeAll();
    },
    styleVar() {
      return this.$refs.tages.style.setProperty(
        "--box-width",
        this.tagswidth + "px"
      ); //给变量赋值
    },
  },
  watch: {
    $route(newValue) {
      this.setTags(newValue);
    },
    // 监听tag的数量
    "$store.state.tagsList": {
      deep: true,
      handler: function (oldValue) {
        this.tagsnubmber = oldValue.length;
        if (this.collapse) {
          // 关闭
          this.tagewidth = document.body.clientWidth - 65;
        } else {
          // 打开
          this.tagewidth = document.body.clientWidth - 251;
        }
        if (this.tagsnubmber > 2) {
          if (document.body.clientWidth > 1910) {
            this.tagswidth = (this.tagewidth - 98) / 8;
            this.styleVar();
          } else if (document.body.clientWidth > 1600) {
            this.tagswidth = (this.tagewidth - 98) / 8;
            this.styleVar();
          } else if (document.body.clientWidth > 1440) {
            this.tagswidth = (this.tagewidth - 98) / 7;
            this.styleVar();
          } else if (document.body.clientWidth > 1366) {
            this.tagswidth = (this.tagewidth - 98) / 7;
            this.styleVar();
          } else if (document.body.clientWidth > 1280) {
            this.tagswidth = (this.tagewidth - 98) / 7;
            this.styleVar();
          } else if (document.body.clientWidth > 800) {
            this.tagswidth = (this.tagewidth - 98) / 7;
            this.styleVar();
          }
          if (this.tagswidth < 165.714) {
            this.tagswidth = 165.714;
            this.styleVar();
          }
        }
      },
    },
  },
  created() {
    this.setTags(this.$route);
  },
  mounted() {
    // 计算tag宽度
    this.styleVar();
    // 监听可视窗口变化
    window.addEventListener(
      "resize",
      () => {
        if (this.collapse) {
          // 关闭
          this.tagewidth = document.body.clientWidth - 65;
        } else {
          // 打开
          this.tagewidth = document.body.clientWidth - 251;
        }
        if (document.body.clientWidth > 1910) {
          this.tagswidth = (this.tagewidth - 98) / 8;
          this.styleVar();
        } else if (document.body.clientWidth > 1600) {
          this.tagswidth = (this.tagewidth - 98) / 8;
          this.styleVar();
        } else if (document.body.clientWidth > 1440) {
          this.tagswidth = (this.tagewidth - 98) / 7;
          this.styleVar();
        } else if (document.body.clientWidth > 1366) {
          this.tagswidth = (this.tagewidth - 98) / 7;
          this.styleVar();
        } else if (document.body.clientWidth > 1280) {
          this.tagswidth = (this.tagewidth - 98) / 7;
          this.styleVar();
        } else if (document.body.clientWidth > 800) {
          this.tagswidth = (this.tagewidth - 98) / 7;
          this.styleVar();
        }
        if (this.tagswidth < 165.714) {
          this.tagswidth = 165.714;
          this.styleVar();
        }
      },
      false
    );
  },
};
</script>
<style lang="less" scoped>
.tags {
  position: relative;
  height: 34px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 5px 0px -5px #ddd;
  padding-right: 34px;
  border-top: 1px solid #0069b5;
  border-bottom: 1px solid #0069b5;
  // min-width: 1290px;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 30px;
}

.tags-li {
  float: left;
  margin: 0 5px 0 3px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 0 5px 0 12px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li.active {
  color: #fff;
  background-color: #088ef0;
}

.tags-li-title {
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
  text-align: center;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  border-left: 1px solid #ddd;
  text-align: center;
  width: 34px;
  height: 34px;
  z-index: 10;
  line-height: 34px;
}
.iconfont-left-tags {
  position: absolute;
  padding-top: 3px;
  cursor: pointer;
}
.iconfont-right-tags {
  position: absolute;
  right: 20px;
  padding-top: 1px;
  margin-right: 30px;
  cursor: pointer;
}
.el-tabs__item {
  padding: 0px 10px !important;
  line-height: 34px !important;
  height: 34px !important;
  width: 170px !important;
}
.el-tabs__item:hover {
  color: #000 !important;
  cursor: pointer !important;
}
/deep/.el-tabs__item .el-icon-close:hover {
  background-color: #fff !important;
  color: #000 !important;
}
/deep/.el-tabs__item .el-icon-close {
  // background-color: #fff !important;
  // color: #000 !important;
  right: 4px;
  position: absolute;
  top: 10px;
  transition: none !important;
}
.el-tabs__item.is-active {
  background: #0069b5 !important ;
  color: #fff !important;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-radius: 0 !important;
}
/deep/.el-tabs__nav-next,
.el-tabs__nav-prev {
  width: 31px !important;
  text-align: center !important;
  color: #606266 !important;
  background: #fff !important;
  height: 34px;
  line-height: 34px;
  z-index: 12;
  border-left: 1px solid #ddd;
  border-right: none;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-top: none !important;
}
.el-tabs__header {
  margin: 0 !important;
}
.el-tabs__nav-wrap {
  margin-bottom: 0 !important;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-closable .el-icon-close {
  opacity: 1 !important;
  width: 18px !important;
  float: right !important;
  top: 9px !important;
  /* margin-left: 25px !important; */
}
.el-scrollbar {
  overflow: hidden;
  position: relative;
  /* height: 95px !important; */
}
.el-tabs__nav-wrap.is-scrollable {
  padding: 0 30px !important;
}
.el-tabs__nav-next {
  width: 31px !important;
  text-align: center !important;
  color: #606266 !important;
  background: #e4e7ed !important;
}
/deep/.el-tabs__nav-prev {
  width: 31px !important;
  text-align: center !important;
  color: #606266 !important;
  background: #fff !important;
  height: 34px;
  line-height: 34px;
  z-index: 12;
  border-right: 1px solid #ddd;
}
.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-bottom: none !important;
}
/deep/.el-tabs__item.is-active:last-child {
  text-align: center !important;
}
/deep/.el-tabs__item.is-active {
  border-top: 1px solid #0069b5;
  background-color: #0069b5;
  color: #fff;
  width: var(--box-width);
  text-align: center !important;
}
/deep/.el-tabs__item.is-active:hover {
  border-top: 1px solid #0069b5;
  background-color: #0069b5;
  color: #fff;
  width: var(--box-width);
  text-align: center !important;
}
/deep/.el-tabs__item {
  // border-top: 1px solid #ddd;
  height: 35px;
  line-height: 35px;
  width: var(--box-width);
  border-right: 1px solid #ddd;
  text-align: center !important;
}
/deep/.el-tabs__item:hover {
  color: #0a0a0a;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item .el-icon-close {
  right: -62px;
}
/deep/.el-tabs__active-bar {
  // background-color: #0069b5;
  transition: all 0 ease 0;
  height: 0px;
}
/deep/ .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}
/deep/.el-tabs__nav-wrap.is-scrollable {
  padding: 0 31px !important;
}
/deep/ .el-tabs__item .el-icon-close:before {
  // transition: none !important;
  transform: scale(1) !important;
}
// /deep/.el-tabs--bottom .el-tabs__item.is-bottom:last-child,
// .el-tabs--bottom .el-tabs__item.is-top:last-child,
// .el-tabs--top .el-tabs__item.is-bottom:last-child,
// .el-tabs--top .el-tabs__item.is-top:last-child {
//   padding-right: 20px !important;
// }
// /deep/ .el-tabs__item.is-top:last-child {
//   padding-right: 20px !important;
// }
// /deep/.el-tabs__item.is-active:last-child(2) {
//   padding-right: 20px !important;
// }
</style>
