<template>
  <div>
    <div class="common-clear-left" v-if="clearLeft"></div>
    <div class="common-clear-right" v-if="clearRight"></div>
    <div class="common-clear-both" v-if="clearBoth"></div>
  </div>
</template>
<script>
export default {
  props: ["clearLeft", "clearRight", "clearBoth"],
};
</script>
<style>
.common-clear-left {
  clear: left;
}
.common-clear-right {
  clear: right;
}
.common-clear-both {
  clear: both;
}
</style>
