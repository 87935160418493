import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import CommonTable from "./components/CommonTable.vue";
import CommonButton from "./components/CommonButton.vue";
import CommonTopInformation from "./components/CommonTopInformation.vue";
import CommonDialog from "./components/CommonDialog.vue";
import CommonSearchArea from "./components/CommonSearchArea.vue";
import CommonUpload from "./components/CommonUpload.vue";
import CommonClearFloat from "./components/CommonClearFloat.vue";
import CommonDialogqcode from "@/components/CommonDialogqcode.vue";
import axios from "axios";
import moment from 'moment'; //导入模块
// moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 



import "element-plus/lib/theme-chalk/index.css";
import "./assets/css/icon.css";

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$moment = moment;//赋值使用
app
  .use(store)
  .use(ElementPlus)
  .use(router)
  .component("common-table", CommonTable)
  .component("common-button", CommonButton)
  .component("common-top-information", CommonTopInformation)
  .component("common-dialog", CommonDialog)
  .component("common-search-area", CommonSearchArea)
  .component("common-upload", CommonUpload)
  .component("common-clear-float", CommonClearFloat)
  .component("common-Dialog-qcode", CommonDialogqcode)
  .mount("#app");

axios.defaults.baseURL = "/api";
axios.defaults.timeout = 500000;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
